import { Link } from "react-router-dom";

function Welcome() {
  return (
    <div className="background">
      <div className="welcome-flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 445.92 72.21"
          width="320"
          className="nav-logo"
        >
          <path
            fill="#fff"
            d="M15.47 0h21.26L25.37 51.68h19.77l2.75 18.52H0L15.47 0zm102.14 26.56L133.26 0H108.1Q96.46 21.51 84.81 43l32.8-16.47"
          />
          <path
            fill="#fff"
            d="M82.53 47.26 77.79 0H54.74l10.42 70.21h26.73l18.54-31.44-27.9 8.49"
          />
          <path
            fill="#b49b57"
            d="M178.78 52.4a23.08 23.08 0 0 1-13.76 5c-4.76 0-8.92-1.57-11.42-4.31s-3.68-7-3.29-11.46a22 22 0 0 1 6.3-13.37c3.64-3.63 8-5.33 13.88-5.33s10.16 1.85 13.62 5.65l1.3 1.43 3.51-17.09-.58-.36c-1.78-1.09-7.19-4.42-17-4.42a37 37 0 0 0-25.25 9.48 38.27 38.27 0 0 0-11.92 25c-.76 8.62 1.4 15.79 6.45 21.33s12.31 8.24 20.95 8.24a34.82 34.82 0 0 0 15.14-3.3l.44-.21 3.73-17.84zm32.32-42.35-31.21 61.32h18.75l5.07-11.12h16.57l.41 11.12h17.51L233 10.05zm8.62 35.57h-9.29l8.15-17.85c.15-.31.29-.63.43-.95zm50.71-21.6c3.07 0 6.89 1.16 11.35 3.46l.94.48L289 14.12l-.8-.45a36.2 36.2 0 0 0-17.94-4.47A25.88 25.88 0 0 0 259 11.62a18.37 18.37 0 0 0-10.73 17 19.2 19.2 0 0 0 2.27 9.2 27.59 27.59 0 0 0 7.14 8.08c2.55 2.13 4.14 3.51 4.7 4.12a7.29 7.29 0 0 1 1.22 1.67 3.49 3.49 0 0 1 .37 1.6 3.32 3.32 0 0 1-1.17 2.79 6.13 6.13 0 0 1-3.91 1 24.9 24.9 0 0 1-7.66-1.41 35.23 35.23 0 0 1-7.59-3.51l-1.53-1v16.66l.44.3a23.15 23.15 0 0 0 7.17 3 35.84 35.84 0 0 0 8.68 1c7.32 0 13.14-1.76 17.28-5.24S282 58.44 282 52.15a18.22 18.22 0 0 0-2.45-9.44 27 27 0 0 0-7.36-7.71 30 30 0 0 1-5-4.42 4.88 4.88 0 0 1-1.14-3.1 3 3 0 0 1 1-2.55 5.3 5.3 0 0 1 3.38-.91zm27.93-13.97L285.4 71.37h18.15l12.96-61.32h-18.15zm66.55 0-5.49 25.62c-.6 2.87-1.21 6.64-1.83 11.24l-10.72-36.86h-20l-13 61.32h16.17l5.4-25.54c.61-2.78 1.24-7.05 1.87-12.72l10.76 38.26h20l13-61.32zm73.4 7.94c-5.52-5.84-13.12-8.79-22.57-8.79-12.77 0-20.69 5.55-24.28 8.86a37 37 0 0 0-11.23 22.82c-.73 8.66 2 16.85 7.53 22.46 3.18 3.31 10.35 8.87 22.73 8.87 9.37 0 17.45-3 24-8.85a34.12 34.12 0 0 0 11.3-22.57c.76-8.82-1.9-16.92-7.48-22.8zm-14.17 35.1a18.35 18.35 0 0 1-12.69 4.71c-4.82 0-8.45-1.43-11.42-4.52-3.11-3.3-4.41-7.36-4-12.4A18.57 18.57 0 0 1 402 28.34a18.36 18.36 0 0 1 12.52-4.72 16.36 16.36 0 0 1 11.75 4.68c1.31 1.44 4.31 5.52 3.72 12.42a18 18 0 0 1-5.85 12.37z"
          />
        </svg>
        <Link to="/recipes">Login as guest</Link>
      </div>
    </div>
  );
}

export default Welcome;
