import React, { useState, useEffect } from "react";
import MenuList from "../components/MenuList";
import ContentList from "../components/ContentList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { recipiesEN } from "../data/en";
import { recipiesDE } from "../data/de";
import { recipiesPL } from "../data/pl";
import { ReactComponent as Logo } from "../svgs/logo.svg";
import { ReactComponent as Polish } from "../svgs/polish.svg";
import { ReactComponent as English } from "../svgs/english.svg";
import { ReactComponent as German } from "../svgs/german.svg";
import { ReactComponent as Ingridients } from "../svgs/ingridients.svg";
import { ReactComponent as Recipies } from "../svgs/recipies.svg";

function Recipes() {
  const { t, i18n } = useTranslation();
  const [category, setCategory] = useState("pizza");
  const [categoryData, setCategoryData] = useState([]);
  const [foodClicked, setFoodClicked] = useState(null);

  const saveSetCategory = (enteredSetCategory) => {
    setCategory(enteredSetCategory);
  };
  const saveSetFoodClicked = (enteredSetFoodClicked) => {
    setFoodClicked(enteredSetFoodClicked);
  };
  const saveResetFoodClicked = (enteredsaveResetFoodClicked) => {
    setFoodClicked(enteredsaveResetFoodClicked);
  };
  const polishTranslation = (length) => {
    if (length === 1) return t("ingredients3");
    if (length < 5 && length > 1) return t("ingredients4");
    else return t("ingredients2");
  };

  useEffect(() => {
    // setFoodClicked(null);
    let recipesLang = [];
    if (i18n.language === "en") recipesLang = recipiesEN;
    if (i18n.language === "pl") recipesLang = recipiesPL;
    if (i18n.language === "de") recipesLang = recipiesDE;
    setCategoryData(recipesLang.filter((el) => el.category === category));
  }, [category, i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [foodClicked]);

  return (
    <React.Fragment>
      <div className="grid spacer">
        <nav>
          <div>
            <Link to="/" aria-label="Recipes">
              <Logo />
            </Link>
          </div>
          <div className="nav-flags">
            <button
              onClick={() => i18n.changeLanguage("pl")}
              className={i18n.language !== "pl" ? "flag-inactive" : ""}
            >
              <Polish />
            </button>
            <button
              onClick={() => i18n.changeLanguage("en")}
              className={i18n.language !== "en" ? "flag-inactive" : ""}
            >
              <English />
            </button>
            <button
              onClick={() => i18n.changeLanguage("de")}
              className={i18n.language !== "de" ? "flag-inactive" : ""}
            >
              <German />
            </button>
          </div>
        </nav>
        <div className="menu">
          <div className="menu-2">
            <MenuList
              title={t("cat1")}
              count="2"
              category="śniadania"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
            <MenuList
              title={t("cat2")}
              count="3"
              category="przystawki"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
            <MenuList
              title={t("cat3")}
              count="10"
              category="pizza"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
            <MenuList
              title={t("cat4")}
              count="5"
              category="makarony"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
            <MenuList
              title={t("cat5")}
              count="2"
              category="sałatki"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
            {/* <MenuList
              title="Pierogi"
              count="4"
              category="pierogi"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            /> */}
            <MenuList
              title={t("cat6")}
              count="3"
              category="zupy"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
            <MenuList
              title={t("cat7")}
              count="4"
              category="burgery"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
            <MenuList
              title={t("cat8")}
              count="6"
              category="danie_główne"
              currentCategory={category}
              onSetCategory={saveSetCategory}
              onResetFoodClicked={saveResetFoodClicked}
            />
          </div>
        </div>
        {foodClicked === null && (
          <div className="content">
            {categoryData.map((el, i) => {
              return (
                <ContentList
                  data={el}
                  index={i}
                  key={el.id}
                  translation={`${polishTranslation(
                    Object.keys(el.ingridients).length
                  )}`}
                  onSetFoodClicked={saveSetFoodClicked}
                />
              );
            })}
            {categoryData.length === 2 && <div></div>}
            {categoryData.length === 1 && (
              <React.Fragment>
                <div></div>
                <div></div>
              </React.Fragment>
            )}
          </div>
        )}
        {foodClicked !== null && (
          <div className="content-2">
            <div className="content-2-left">
              <video
                preload="auto"
                width="1280"
                height="720"
                muted
                playsInline
                controls
              >
                <source
                  src={`/videos/${categoryData[foodClicked].video}`}
                  type="video/mp4"
                />
              </video>
            </div>
            <h2 className="content-title">{categoryData[foodClicked].title}</h2>
            <div className="content-2-ingridients">
              <div className="content-category">
                <Ingridients />
                <h3 className="content-category-title">{t("ingredients")}</h3>
                <p>{`${
                  Object.keys(categoryData[foodClicked].ingridients).length
                } ${
                  i18n.language === "pl"
                    ? polishTranslation(
                        Object.keys(categoryData[foodClicked].ingridients)
                          .length
                      )
                    : t("ingredients2")
                }`}</p>
              </div>
              {Object.entries(categoryData[foodClicked].ingridients).map(
                ([key, value]) => {
                  return (
                    <React.Fragment key={key}>
                      <div className="content-ingridient">
                        <p className="ing-1">{`${key[0].toUpperCase()}${key
                          .slice(1)
                          .replaceAll("_", " ")}`}</p>
                        {value.startsWith("-") ? (
                          ""
                        ) : (
                          <p className="ing-2">{value}</p>
                        )}
                      </div>
                      <div className="line"></div>
                    </React.Fragment>
                  );
                }
              )}
            </div>
            <div className="content-2-left-2">
              <div className="content-category">
                <Recipies />
                <h3 className="content-category-title">{t("recipe")}</h3>
              </div>
              <p className="content-recipe">
                {categoryData[foodClicked].content}
              </p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Recipes;
