function MenuList({ title, count, category, currentCategory, onSetCategory, onResetFoodClicked }) {
  return (
    <button
      className={`menu-button ${currentCategory === category ? "toggle" : ""}`}
      onClick={() => {
        onResetFoodClicked(null);
        onSetCategory(category);
      }}
    >
      <p>{title}</p>
      <div className="menu-button-count">
        <p>{count}</p>
      </div>
      <div className="dropdown-button">
        <div className="line-1"></div>
        <div className="line-2"></div>
        <div className="line-3"></div>
      </div>
    </button>
  );
}

export default MenuList;
