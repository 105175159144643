import React from "react";
import { Route, Switch } from "react-router-dom";
import "./global.css";
import Welcome from "./pages/Welcome";
import Recipes from "./pages/Recipes";

function App() {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact>
          <Welcome />
        </Route>
        <Route path="/recipes">
          <Recipes />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
