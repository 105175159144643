export const recipiesEN = [
  {
    id: 1,
    category: "śniadania",
    title: "Shakshuka & chorizo",
    content: "",
    image: "shakshuhachorrizo.webp",
    video: "shakshuhachorrizo.mp4",
    ingridients: {},
  },
  {
    id: 2,
    category: "śniadania",
    title: "Shakshuka & feta",
    content: "",
    image: "shakshuhafeta.webp",
    video: "shakshuhafeta.mp4",
    ingridients: {},
  },
  {
    id: 3,
    category: "przystawki",
    title: "Beef carpaccio",
    content:
      "Place the thinly sliced beef sirloin (previously rolled and frozen) on the oiled (sansa) pizza plate. Then salt, pepper and brushlightly with mustard sauce. Mix the arugula with a little truffle oil and put it on a plate. Add cherry tomatoes, capers, Parmesan flakes, and bread.",
    image: "beefcarpaccio.webp",
    video: "beefcarpaccio.mp4",
    ingridients: {
      Sirloin: "-",
      Oil: "-",
      Mustard_sauce: "-",
      Arugula: "-",
      Truffle_oil: "-",
      Cherry_tomatoes: "-",
      Capers: "-",
      Parmesan_flakes: "-",
      Bread: "2 pcs.",
    },
  },
  {
    id: 4,
    category: "przystawki",
    title: "Shrimp in wine",
    content:
      "6 krewetek podsmażonych z czosnkiem i cebulą odrobina płatków chilli, podlane białym winem dodać masło około 60g, „wkręcić” masło w wino do uzyskania błyszczącego sosu maślanego, posypać natką pietruszki doprawić pieprzem i solą, podawać z 2szt trójkąciki z ciasta do pizzy, talerz duży kwadratowy.",
    image: "shrimpinwine.webp",
    video: "shrimpinwine.mp4",
    ingridients: {
      Shrimps: "6 pcs.",
      Butter: "60g",
      Parsley: "-",
      Garlic: "-",
      Onion: "-",
      Chilli_flakes: "-",
      White_wine: "-",
      Pepper: "-",
      Salt: "-",
      Pizza_triangles: "2 pcs.",
    },
  },
  {
    id: 5,
    category: "przystawki",
    title: "Tatar",
    content: "",
    image: "tatar.webp",
    video: "tatar.mp4",
    ingridients: {
      Tatar: "250g",
    },
  },
  {
    id: 6,
    category: "zupy",
    title: "Tomato cream",
    content:
      "A portion of soup about 350g, boiled, served with a cube of white mozzarella (30g)",
    image: "tomatocream.webp",
    video: "tomatocream.mp4",
    ingridients: {
      Soup: "350g",
      White_mozzarella: "30g",
    },
  },
  {
    id: 7,
    category: "zupy",
    title: "Chicken broth",
    content:
      "A portion of the boiled soup about 350g. Served with Capellini pasta, cooked diced carrots, sprinkled with freshly chopped parsley",
    image: "chickenbroth.webp",
    video: "chickenbroth.mp4",
    ingridients: {
      Soup: "350g",
      Capellini_pasta: "-",
      Carrot: "-",
      Parsley: "-",
    },
  },
  {
    id: 8,
    category: "zupy",
    title: "Alpejska",
    content: "  ",
    image: "alpinecheesesoup.webp",
    video: "alpinecheesesoup.mp4",
    ingridients: {
      Soup: "350g",
    },
  },
  {
    id: 9,
    category: "burgery",
    title: "Tradycyjny",
    content:
      "1 portion of meat per burger (190g) properly fry according to guests&#39; wishes. Fried portion of fries (130g). Cut the bun inhalf, cover the upper part with ketchup, and cover the lower part with mayonnaise. Add sliced romaine lettuce, pickledcucumber slices, 2 tomato slices, red onion sliced into rings, fried cutlet, so prepared buns go to the pizza oven for 1minute. Fold the burger, pierce it with a toothpick, place it on a piece of waxed paper with fries (not salted) and a bowl of ketchup.",
    image: "traditional.webp",
    video: "traditional.mp4",
    ingridients: {
      Meat_for_burger: "190g",
      French_fries: "130g",
      Burger_bun: "-",
      Roman_salad: "-",
      Pickled_cucumber: "-",
      Tomato: "-",
      Red_onion: "-",
      Ketchup: "-",
    },
  },
  {
    id: 10,
    category: "burgery",
    title: "Cheeseburger",
    content: "  ",
    image: "cheeseburger.webp",
    video: "cheeseburger.mp4",
    ingridients: {
      Meat_for_burger: "190g",
      French_fries: "130g",
    },
  },
  {
    id: 11,
    category: "burgery",
    title: "Stilton",
    content: "  ",
    image: "stilton.webp",
    video: "stilton.mp4",
    ingridients: {
      Meat_for_burger: "190g",
      Stilton_cheese: "-",
      Caramelized_onion_with_whiskey: "-",
      BBQ_sauce: "-",
      French_fries: "130g",
    },
  },
  {
    id: 12,
    category: "burgery",
    title: "Alpejski",
    content: "  ",
    image: "alpine.webp",
    video: "alpine.mp4",
    ingridients: {
      Meat_for_burger: "190g",
      Emmentaler_cheese: "-",
      Mushrooms: "-",
      Caramelized_onion_with_whiskey: "-",
      Mayonnaise_sauce: "-",
      French_fries: "130g",
    },
  },
  {
    id: 13,
    category: "sałatki",
    title: "Caesar salad",
    content:
      "Sałata rzymska pokrojona (wcześniej umyta i wysuszona) wymieszana z sosem cezar posypane 8szt (cząstek pomidorka cherry), 12szt kapara owoc, grzanki( wcześniej przygotowane z masłem rozmarynem i czosnkiem) podawane ze smażoną piersią z kurczaka(soczysta nie przesuszona) 2 plastry chipsów z boczku pokrojone na 3 części (zagrzane) posypane płatkami z parmezanu, udekorowane listkiem świeżej bazyli.",
    image: "caesarsalad.webp",
    video: "caesarsalad.mp4",
    ingridients: {
      Roman_salad: "-",
      Chicken: "-",
      Cherry_tomatoes: "-",
      Capers: "-",
      Parmesan: "-",
    },
  },
  {
    id: 14,
    category: "sałatki",
    title: "Shrimp salad",
    content:
      "Mix lettuce mixed with olive oil (sansa) and juice from 1/8 lemon, mix with a spoon of cooked black lentils, chopped coriander, thin sticks of roasted red pepper, thin mango sticks, Add prawns (6 pcs) fried in olive oil, stewed in wine and dipped in mango dip, coriander and chilli with sugar syrup, served with croutons (such as bruschetta), spread with mascarpone cheese (3 pcs).",
    image: "shrimpsalad.webp",
    video: "shrimpsalad.mp4",
    ingridients: {
      Salad_mix: "-",
      Shrimp: "-",
      Mango: "-",
      Baked_pepper: "-",
      Black_lentils: "-",
      Lemon_dressing: "-",
      Coriander: "-",
      Oil: "-",
    },
  },
  {
    id: 15,
    category: "makarony",
    title: "Spaghetti bolognese",
    content:
      "1 portion of spaghetti. Mix the Bolognese sauce with (3 serving spoons) tomato sauce, bring to the boil, add the pasta. Season with salt and freshly ground pepper. Serve on a deep square plate, sprinkle with Parmesan flakes and add basil leaves.",
    image: "spaghettibolognese.webp",
    video: "spaghettibolognese.mp4",
    ingridients: {
      Spaghetti_pasta: "-",
      Bolognese_sauce: "-",
      Salt: "-",
      Ground_pepper: "-",
      Parmesan: "-",
      Basil: "-",
    },
  },
  {
    id: 16,
    category: "makarony",
    title: "Spaghetti carbonara",
    content:
      "1 portion of spaghetti. 3 slices of steamed smoked bacon, fry with 1/2 tablespoon of chopped garlic, pour over white wine, bring to a boil, add cream, bring to a boil and reduce to a creamy consistency, add pasta. Season with salt and freshly ground pepper, add egg yolk, parsley and mix thoroughly. Serve on a deep square plate, sprinkle with parmesan cheese flakes, add 4 pieces of cherry tomato and basil leaf.",
    image: "spaghetticarbonara.webp",
    video: "spaghetticarbonara.mp4",
    ingridients: {
      Spaghetti_pasta: "-",
      Smoked_bacon: "-",
      Garlic: "-",
      Salt: "-",
      Ground_pepper: "-",
      Egg: "-",
      Parsley: "-",
      Parmesan: "-",
      Cherry_tomatoes: "-",
      Basil: "-",
    },
  },
  {
    id: 17,
    category: "makarony",
    title: "Tagliatelle with salmon",
    content:
      "1 portion of tagliatelle pasta. Fry the raw salmon 0.08 kg with chopped garlic and diced white onion. Add 0.5 tablespoonsblack olives, pour white wine, bring to a boil, add 1.5 tablespoons of mascarpone and a little cream, mix until uniform, add cooked broccoli 5 florets, add pasta. Season with salt and freshly ground pepper. Serve on a deep square plate, add 4 pieces of cherry tomato and a basil leaf.",
    image: "tagliatellesalmon.webp",
    video: "tagliatellesalmon.mp4",
    ingridients: {
      Tagliatelle_pasta: "-",
      Raw_salmon: "80g",
      Garlic: "-",
      White_onion: "-",
      Black_olives: "-",
      White_wine: "-",
      Mascarpone: "-",
      Broccoli: "-",
      Salt: "-",
      Pepper: "-",
      Parmesan: "-",
      Basil: "-",
      Cherry_tomatoes: "-",
    },
  },
  {
    id: 18,
    category: "makarony",
    title: "Tagliatelle with chicken",
    content:
      "1 portion of tagliatelle pasta. Fry the chicken fillet 0.09 kg with chopped garlic and diced white onion. Add mushrooms thinly sliced or half sliced, dried tomatoes, pour white wine, bring to a boil, add cream, bring to a boil and reduce to a creamy consistency, add baby spinach 0.03 kg, add pasta. Season with salt and freshly ground pepper. Serve on a deep square plate, add 4 pieces of cherry tomato and a basil leaf.",
    image: "tagliatellechicken.webp",
    video: "tagliatellechicken.mp4",
    ingridients: {
      Tagliatelle_pasta: "-",
      Chicken_fillet: "90g",
      Garlic: "-",
      White_onion: "-",
      Champignons: "-",
      White_wine: "-",
      Dried_tomatoes: "-",
      Salt: "-",
      Ground_pepper: "-",
      Spinach: "30g",
      Basil: "-",
      Cherry_tomatoes: "-",
    },
  },
  {
    id: 19,
    category: "makarony",
    title: "Tagliolini Nero",
    content:
      "1 portion of nero tagliolini (black). 6 pcs of shrimps fried with chopped garlic and diced white onion, a pinch of dried chilli flakes, pour white wine, bring to a boil, and then add cold butter, stirring constantly until uniform sauce, add baby spinach 0,3kg, add pasta. Season with salt and freshly ground pepper, add parsley. Serve on a deep square plate, add 4 pieces of cherry tomato and a basil leaf.",
    image: "tagliolininero.webp",
    video: "tagliolininero.mp4",
    ingridients: {
      Tagliolini_nero: "-",
      Shrimps: "6 pcs.",
      White_onion: "-",
      Dried_chillies: "-",
      White_wine: "-",
      Spinach: "30g",
      Cherry_tomatoes: "-",
    },
  },
  {
    id: 20,
    category: "danie_główne",
    title: "Pork chop with bone",
    content: " ",
    image: "porkchopwithbone.webp",
    video: "porkchopwithbone.mp4",
    ingridients: {
      Pork_chop_with_bone: "-",
    },
  },
  {
    id: 21,
    category: "danie_główne",
    title: "Grilled salmon",
    content:
      "Salmon 160g, cooked for 12-14 minutes on the circulator. Black lentils heated with blanched spinach, pepper sauce previously prepared and heated. Salmon after swimming in a circulator, fried until golden in butter. On the pizza plate, a zigzag of pepper sauce, in the center a strip of spinach and lentils, salmon with the fried side up, 4 parts of kale chips, 1/8 lemon.",
    image: "grilledsalmon.webp",
    video: "grilledsalmon.mp4",
    ingridients: {
      Salmon: "160g",
      Black_lentils: "6 szt.-",
      Spinach: "-",
      Kale: "-",
      Lemon: "-",
    },
  },
  {
    id: 23,
    category: "danie_główne",
    title: "Hungarian potato fritters",
    content: "  ",
    image: "hungarianpotatofritters.webp",
    video: "hungarianpotatofritters.mp4",
    ingridients: {
      Potato_fritters: "-",
    },
  },
  {
    id: 24,
    category: "danie_główne",
    title: "Sous-vide duck breast",
    content: "  ",
    image: "duckbreast.webp",
    video: "duckbreast.mp4",
    ingridients: {
      Duck_breast: "-",
    },
  },
  {
    id: 25,
    category: "danie_główne",
    title: "Beef tenderloin",
    content:
      "Put 1 portion of previously prepared tenderloin into the circulator (4-6 minutes). Potato gnocchi (about 14 pieces) - boiled and fried in butter, blanched 4 broccoli florets, fry together with the gnocchi. Prepare the bolete sauce (heat the cream with boletus sauce). How to serve: a square flat plate, bolete mushroom sauce in the middle, fried gnocchi and broccoli arranged nicely, pieces of tenderloin fried on one side, all sprinkled with parsley.",
    image: "beeftenderloin.webp",
    video: "beeftenderloin.mp4",
    ingridients: {
      Beef_tenderloin: "-",
      Potato_gnocchi: "-",
      Broccoli: "-",
      Bolete_mushroom_sauce: "-",
      Parsley: "-",
    },
  },
  {
    id: 26,
    category: "danie_główne",
    title: "Risotto",
    content: "",
    image: "risotto.webp",
    video: "risotto.mp4",
    ingridients: {
      Risotto: "-",
    },
  },
  {
    id: 27,
    category: "pizza",
    title: "Margherita",
    content:
      "The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge.",
    image: "pizzamargherita.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "1,5 tbsp",
      Mozzarella_galbani_cheese: "110 g",
      Dried_oregano: "-",
    },
  },
  {
    id: 28,
    category: "pizza",
    title: "Salame",
    content:
      "The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge.",
    image: "pizzasalame.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "1,5 tbsp",
      Mozzarella_galbani_cheese: "110 g",
      Dried_oregano: "-",
      Salami: "8 large slices",
    },
  },
  {
    id: 29,
    category: "pizza",
    title: "Capricciosa",
    content:
      "Canned ham sliced into slices and each slice into four (12 small slices), Champignons thinly sliced or half sliced, dried oregano. The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge.",
    image: "pizzacapriciosa.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "1,5 tbsp",
      Mozzarella_galbani_cheese: "110 g",
      Canned_ham: "-",
      Champignons: "-",
      Artichokes: "-",
      Dried_oregano: "-",
      Black_olives: "-"
    },
  },
  {
    id: 30,
    category: "pizza",
    title: "Diavolo",
    content:
      "Tomato sauce 1 serving spoon + 1 table spoon of hot sauce, mozzarella galbani cheese (0.11 kg), Spicy Picante Salami (14 small slices), sliced jalapeno pepper, dried chilli flakes, dried oregano. The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge.",
    image: "pizzadiavolo.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "-",
      Mozzarella_galbani_cheese: "110 g",
      Hot_sauce: "-",
      Salami_Picante: "-",
      Jalapeno_peppers: "-",
      Dried_chillies: "-",
      Dried_oregano: "-",
    },
  },
  {
    id: 31,
    category: "pizza",
    title: "Montagna",
    content:
      "Tomato sauce (1.5 serving spoons), galbani mozzarella cheese (0.11 kg), Ustrzycka roulade (8 slices), white onion cut into rings, bacon slices 3 pcs, cut into three (bacon chips), poured with mixed cranberries . Add dried oregano. The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge.",
    image: "pizzamontagna.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "-",
      Mozzarella_galbani_cheese: "110 g",
      Ustrzycka_roulade: "-",
      White_onion: "-",
      Bacon_slices: "-",
      Cranberries: "-",
      Dried_oregano: "-",
    },
  },
  {
    id: 32,
    category: "pizza",
    title: "Pesce",
    content:
      "Tomato sauce (1.5 serving spoons), galbani mozzarella cheese (0.11 kg), canned tuna drained from the pickle (0.06 kg), white onion, cut into rings. After baking, add fresh dill and dried oregano. The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge.",
    image: "pizzapesce.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "-",
      Mozzarella_galbani_cheese: "110 g",
      Canned_tuna: "60g",
      White_onion: "-",
      Dill: "-",
      Dried_oregano: "-",
    },
  },
  {
    id: 33,
    category: "pizza",
    title: "Gamberetto",
    content:
      "Sos pomidorowy 1,5 łyżki do serwowania, ser mozzarella galbani 0,11 kg, 4 szt krewetek przecięte wzdłuż ułożone tak aby po pokrojeniu pizzy na każdym kawałku była 1 szt, cukinia pokrojona w cienkie plastry 12szt, po upieczeniu posypać pomidorkami co idą do bruschetta i posiekaną kolendrą, oregano suszone. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzagamberetto.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "-",
      Mozzarella_galbani_cheese: "110 g",
      Shrimps: "4 pcs.",
      Zucchini: "-",
      Tomato: "-",
      Coriander: "-",
      Dried_oregano: "-",
    },
  },
  {
    id: 34,
    category: "pizza",
    title: "Formaggi",
    content:
      "Tomato sauce (1.5 serving spoons), galbani mozzarella cheese (0.11 kg), Ustrzycka roulade (8 slices), pieces of gorgonzola (0.05 kg). After baking, add the almond flakes, Parmesan flakes and dried oregano. The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge",
    image: "pizzaformaggi.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "-",
      Mozzarella_galbani_cheese: "110 g",
      Ustrzycka_roulade: "",
      Gorgonzola: "50 g",
      Almonds: "-",
      Parmesan: "-",
      Dried_oregano: "-",
    },
  },
  {
    id: 35,
    category: "pizza",
    title: "Bianca",
    content:
      "Garlic sauce, mozzarella galbani cheese (0.11 kg), Champignons thinly sliced or semi-sliced, pear thinly sliced on a slicer, crushed goat cheese (0.04 kg), after baking add arugula, parmesan flakes and oregano. The pizza is to be 32 cm thick with a thin crust and a crispy fluffy edge.",
    image: "pizzabianca.webp",
    video: "pizza.mp4",
    ingridients: {
      Garlic_sauce: "-",
      Mozzarella_galbani_cheese: "110 g",
      Champignons: "",
      Pear: "-",
      Goat_cheese: "40 g",
      Arugula: "-",
      Parmesan: "-",
      Oregano: "-",
    },
  },
  {
    id: 36,
    category: "pizza",
    title: "Prosciutto",
    content:
      "Tomato sauce (1.5 serving spoons), mozzarella galbani cheese (0.11 kg). After baking, add 4 slices of Parma ham - half for each slice of pizza, arugula sprinkled with pomace / sansa oil, cherry tomatoes - ½ or ¼ cut, sprinkle with parmesan flakes and dried oregano. The pizza is to be 32 cm in size, with a thin crust and a crispy fluffy edge.",
    image: "pizzaprosciutto.webp",
    video: "pizza.mp4",
    ingridients: {
      Tomato_sauce: "-",
      Mozzarella_galbani_cheese: "110 g",
      Parma_ham: "",
      Arugula: "-",
      Oliwa_pomace_or_sansa: "-",
      Cherry_tomatoes: "-",
      Parmesan: "-",
      Dried_oregano: "-",
    },
  },
];
