import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "de", "pl"],
    whitelist: ["en", "de", "pl"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    // react: { useSuspense: false },
  });
const loading = <span></span>;
ReactDOM.render(
  <Suspense fallback={loading}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
