export const recipiesPL = [
  {
    id: 1,
    category: "śniadania",
    title: "Shakshuha & chorrizo",
    content: "",
    image: "shakshuhachorrizo.webp",
    video: "shakshuhachorrizo.mp4",
    ingridients: {},
  },
  {
    id: 2,
    category: "śniadania",
    title: "Shakshuha & feta",
    content: "",
    image: "shakshuhafeta.webp",
    video: "shakshuhafeta.mp4",
    ingridients: {},
  },
  {
    id: 3,
    category: "przystawki",
    title: "Carpaccio wołowe",
    content:
      "Cienko pokrojone plastry polędwicy wołowej(wcześniej zwinięta i zamrożona) układane na talerzu do pizzy wcześniej posmarować talerz oliwa (sansa) popieprzyć posolić posmarować delikatnie sosem musztardowym, posypać rukolą wymieszaną z odrobiną oliwy truflowej, pomidorki cherry, kapary, płatki parmezanu, 2 paluszki grissini.",
    image: "beefcarpaccio.webp",
    video: "beefcarpaccio.mp4",
    ingridients: {
      Polędwica_Wołowa: "-",
      Oliwa: "-",
      Sos_Musztardowy: "-",
      Rukola: "-",
      Oliwa_truflowa: "-",
      Pomidorki_cherry: "-",
      Kapary: "-",
      Parmezan: "-",
      Paluszki_grissini: "2 szt",
    },
  },
  {
    id: 4,
    category: "przystawki",
    title: "Krewetki na winie",
    content:
      "6 krewetek podsmażonych z czosnkiem i cebulą odrobina płatków chilli, podlane białym winem dodać masło około 60g, „wkręcić” masło w wino do uzyskania błyszczącego sosu maślanego, posypać natką pietruszki doprawić pieprzem i solą, podawać z 2szt trójkąciki z ciasta do pizzy, talerz duży kwadratowy.",
    image: "shrimpinwine.webp",
    video: "shrimpinwine.mp4",
    ingridients: {
      Krewetki: "6 szt",
      Masło: "60g",
      Natka_pietruszki: "-",
      Czosnek: "-",
      Cebula: "-",
      Płatki_chilli: "-",
      Białe_wino: "-",
      Pieprz: "-",
      Sól: "-",
      Trójkąciki_do_pizzy: "2 szt",
    },
  },
  {
    id: 5,
    category: "przystawki",
    title: "Tatar",
    content: "",
    image: "tatar.webp",
    video: "tatar.mp4",
    ingridients: {
      Krewetki: "6 szt",
      Masło: "60g",
      Natka_pietruszki: "-",
      Czosnek: "-",
      Cebula: "-",
      Płatki_chilli: "-",
      Białe_wino: "-",
      Pieprz: "-",
      Sól: "-",
      Trójkąciki_do_pizzy: "2 szt",
    },
  },
  {
    id: 6,
    category: "zupy",
    title: "Krem pomidorowy",
    content:
      "Porcja zupy około 350g, zagotowane, podawane z kostką z białej mozzareli 30g.",
    image: "tomatocream.webp",
    video: "tomatocream.mp4",
    ingridients: {
      Zupa: "350g",
      Biała_mozzarella: "30g",
    },
  },
  {
    id: 7,
    category: "zupy",
    title: "Rosół",
    content:
      "Porcja zupy około 350g, zagotowane, podawane z makaronem nitki, ugotowaną marchewką pokrojoną w kosteczkę, posypać świeżo posiekaną natką pietruszki.",
    image: "chickenbroth.webp",
    video: "chickenbroth.mp4",
    ingridients: {
      Zupa: "350g",
      Makaron_nitki: "-",
      Marchewka: "-",
      Natka_pietruszki: "-",
    },
  },
  {
    id: 8,
    category: "zupy",
    title: "Alpejska",
    content: "  ",
    image: "alpinecheesesoup.webp",
    video: "alpinecheesesoup.mp4",
    ingridients: {
      Zupa: "350g",
    },
  },
  {
    id: 9,
    category: "burgery",
    title: "Tradycyjny",
    content:
      "1 porcja mięsa na burgera(190g), odpowiednio wysmażone według życzenia gości,smażona porcja frytek(130g), bułka przekrojona na pół, górna część posmarować ketchupem, dolna część bułki posmarowana majonezem,sałata rzymska krojona, plastry ogórka konserwowego, 2 plastry pomidora, cebula czerwona pokrojona w krążki, usmażony kotlet, tak przygotowane bułki trafiają na 1 minutę do pieca od pizzy, burgera złożyć przebić wykałaczką ułożyć na kawałku papieru woskowanego z frytkami(nie solone) i kokilką ketchupu.",
    image: "traditional.webp",
    video: "traditional.mp4",
    ingridients: {
      Mięsa_na_burgera: "190g",
      Frytki: "130g",
      Bułka: "-",
      Sałata_rzymska: "-",
      Ogórek_konserwowy: "-",
      Pomidor: "-",
      Cebula_czerwona: "-",
      Ketchup: "-",
    },
  },
  {
    id: 10,
    category: "burgery",
    title: "Cheeseburger",
    content: "  ",
    image: "cheeseburger.webp",
    video: "cheeseburger.mp4",
    ingridients: {
      Mięsa_na_burgera: "190g",
      Frytki: "130g",
    },
  },
  {
    id: 11,
    category: "burgery",
    title: "Stilton",
    content: "  ",
    image: "stilton.webp",
    video: "stilton.mp4",
    ingridients: {
      Mięsa_na_burgera: "190g",
      Ser_Stilton: "-",
      Cebula_Karmelizowana_z_whiskey: "-",
      Sos_BBQ: "-",
      Frytki: "130g",
    },
  },
  {
    id: 12,
    category: "burgery",
    title: "Alpejski",
    content: "  ",
    image: "alpine.webp",
    video: "alpine.mp4",
    ingridients: {
      Mięsa_na_burgera: "190g",
      Ser_Emmentaler: "-",
      Pieczarki: "-",
      Karmelizowana_cebula_z_whiskey: "-",
      Sos_majonezowy: "-",
      Frytki: "130g",
    },
  },
  {
    id: 13,
    category: "sałatki",
    title: "Sałatka Cezar",
    content:
      "Sałata rzymska pokrojona (wcześniej umyta i wysuszona) wymieszana z sosem cezar posypane 8szt (cząstek pomidorka cherry), 12szt kapara owoc, grzanki( wcześniej przygotowane z masłem rozmarynem i czosnkiem) podawane ze smażoną piersią z kurczaka(soczysta nie przesuszona) 2 plastry chipsów z boczku pokrojone na 3 części (zagrzane) posypane płatkami z parmezanu, udekorowane listkiem świeżej bazyli.",
    image: "caesarsalad.webp",
    video: "caesarsalad.mp4",
    ingridients: {
      Sałata_rzymska: "-",
      Kurczak: "-",
      Pieczarki: "-",
      Pomidorkami_cherry: "-",
      Kapary: "-",
      Parmezan: "-",
    },
  },
  {
    id: 14,
    category: "sałatki",
    title: "Sałatka z krewetkami",
    content:
      "Mix sałat wymieszany z oliwą(sansa) i sokiem z 1/8 cytryny, wymieszać z łyżką ugotowanej czarnej soczewicy, posiekaną kolendrą, cienkie słupki z pieczonej czerwonej papryki, cienkie słupki z mango, krewetki(6szt) smażone na oliwie duszone w winie maczane w salsie z mango kolendry i chilli z dodatkiem syropu cukrowego, podawane z grzankami(takie jak do bruschetty posmarowane serkiem mascarpone (3szt).",
    image: "shrimpsalad.webp",
    video: "shrimpsalad.mp4",
    ingridients: {
      Mix_sałat: "-",
      Krewetki: "-",
      Mango: "-",
      Pieczona_papryka: "-",
      Czarna_soczewica: "-",
      Dressing_cytrynowy: "-",
      Kolendra: "-",
      Oliwa: "-",
    },
  },
  {
    id: 15,
    category: "makarony",
    title: "Spaghetti bolognese",
    content:
      "1 porcja makaronu spaghetti, sos bolognese wymieszać z 3 łyżkami do serwowania sosu pomidorowego, zagotować dodać makaron dodać makaron przyprawić solą i świeżo mielonym pieprzem, podawać na głębokim kwadratowym talerzu,posypać parmezanem w płatkach, listek bazyli.",
    image: "spaghettibolognese.webp",
    video: "spaghettibolognese.mp4",
    ingridients: {
      Makaron_spaghetti: "-",
      Sos_Bolognese: "-",
      Sól: "-",
      Mielony_pieprz: "-",
      Parmezan: "-",
      Bazylia: "-",
    },
  },
  {
    id: 16,
    category: "makarony",
    title: "Spaghetti carbonara",
    content:
      "1 porcja makaronu spaghetti, 3plastry boczku wędzone parzonego w plastrach, przesmażony z 1/2 łyżki posiekanego czosnku, podlać białym winem zagotować dodać śmietanę, zagotować i zredukować do kremowej konsystencji, dodać makaron, przyprawić solą i świeżo mielonym pieprzem, dodać żółtko jajka natkę pietruszki dokładnie wymieszać, podawać na głębokim kwadratowym talerzu, posypać parmezanem w płatkach , 4 cząstki pomidorka cherry listek bazyli.",
    image: "spaghetticarbonara.webp",
    video: "spaghetticarbonara.mp4",
    ingridients: {
      Makaron_spaghetti: "-",
      Boczek_wędzony: "-",
      Czosnek: "-",
      Sól: "-",
      Mielony_pieprz: "-",
      Jajko: "-",
      Natka_pietruszki: "-",
      Parmezan: "-",
      Pomidorki_Cherry: "-",
      Bazylia: "-",
    },
  },
  {
    id: 17,
    category: "makarony",
    title: "Tagiatelle z łososiem",
    content:
      "1 porcja makaronu tagiatelle, łosoś surowy 0,08kg, przesmażyć z posiekanym czosnkiem pokrojoną w kostkę cebulą białą, oliwki czarne 0,5 łyżki, podlać białym winem zagotować dodać 1,5 łyżki mascarpone trochę śmietany, wymieszać do uzyskania jednolitej konsystencji, dodać ugotowany brokuł 5 różyczek,  dodać makaron przyprawić solą i świeżo mielonym pieprzem, podawać na głębokim kwadratowym talerzu, , 4 cząstki pomidorka cherry listek bazyli.",
    image: "tagliatellesalmon.webp",
    video: "tagliatellesalmon.mp4",
    ingridients: {
      Makaron_tagiatelle: "-",
      Łosoś_surowy: "80g",
      Czosnek: "-",
      Cebula_biała: "-",
      Oliwki_czarne: "-",
      Białe_wino: "-",
      Mascarpone: "-",
      Sól: "-",
      Pieprz: "-",
      Parmezan: "-",
      Bazylia: "-",
      Pomidorki_Cherry: "-",
    },
  },
  {
    id: 18,
    category: "makarony",
    title: "Tagiatelle z kurczakiem",
    content:
      "1 porcja makaronu tagiatelle, filet z kurczaka 0,09kg, przesmażyć z posiekanym czosnkiem pokrojoną w kostkę cebulą białą, pieczarki cienko krojone w plastry lub pół plastry, suszone pomidory, podlać białym winem zagotować dodać śmietanę, zagotować i zredukować do kremowej konsystencji, dodać szpinak baby 0,03kg , dodać makaron, przyprawić solą i świeżo mielonym pieprzem, podawać na głębokim kwadratowym talerzu, , 4cząstki pomidorka cherry listek bazyli.",
    image: "tagliatellechicken.webp",
    video: "tagliatellechicken.mp4",
    ingridients: {
      Makaron_tagiatelle: "-",
      Filet_z_kurczaka: "90g",
      Czosnek: "-",
      Cebula_biała: "-",
      Pieczarki: "-",
      Białe_wino: "-",
      Suszone_pomidory: "-",
      Sol: "-",
      Mielony_pieprz: "-",
      Szpinak: "30g",
      Bazylia: "-",
      Pomidorki_Cherry: "-",
    },
  },
  {
    id: 19,
    category: "makarony",
    title: "Tagiolini nero",
    content:
      "1 porcja makaronu tagiolini nero(czarny), 6 szt krewetek przesmażonych z posiekanym czosnkiem pokrojoną w kostkę cebulą białą, szczypta płatków suszone chilli, podlać białym winem zagotować dodać zimne masło ciągle mieszając aż do uzyskania jednolitego sosu, dodać szpinak baby 0,3kg , dodać makaron przyprawić solą i świeżo mielonym pieprzem, natkę  pietruszki, podawać na głębokim kwadratowym talerzu, , 4 cząstki pomidorka cherry listek bazyli.",
    image: "tagliolininero.webp",
    video: "tagliolininero.mp4",
    ingridients: {
      Tagiolini_nero: "-",
      Krewetki: "6 szt.",
      Cebula_biała: "-",
      Suszone_chilli: "-",
      Białe_wino: "-",
      Szpinak: "30g",
      Pomidorki_Cherry: "-",
    },
  },
  {
    id: 20,
    category: "danie_główne",
    title: "Kotlet schabowy z kością",
    content: " ",
    image: "porkchopwithbone.webp",
    video: "porkchopwithbone.mp4",
    ingridients: {
      Kotlet_schabowy_z_kością: "-",
    },
  },
  {
    id: 21,
    category: "danie_główne",
    title: "Grillowany łosoś",
    content:
      "Łosoś 160g, gotowany 12-14 minut w cyrkulatorze , soczewica czarna nagrzana z blanszowanym szpinakiem, sos paprykowy wcześniej przygotowany podgrzany, Łosoś po kąpieli w cyrkulatorze smażony na złoto na maśle, Na talerzu od pizzy zygzak z sosu paprykowego, pośrodku pasek ze szpinaku i soczewicy na to łosoś przesmażoną stroną do góry, 4 częśći chipsa z jarmużu, 1/8 cytryny.",
    image: "grilledsalmon.webp",
    video: "grilledsalmon.mp4",
    ingridients: {
      Łosoś: "160g",
      Soczewica_czarna: "6 szt.-",
      Szpinak: "-",
      Jarmuż: "-",
      Cytryna: "-",
    },
  },
  {
    id: 23,
    category: "danie_główne",
    title: "Placki ziemniaczane po węgiersku",
    content: "  ",
    image: "hungarianpotatofritters.webp",
    video: "hungarianpotatofritters.mp4",
    ingridients: {
      Placki_ziemniaczane: "-",
    },
  },
  {
    id: 24,
    category: "danie_główne",
    title: "Pierś z kaczki sous-vide",
    content: "  ",
    image: "duckbreast.webp",
    video: "duckbreast.mp4",
    ingridients: {
      Pierś_z_kaczki: "-",
    },
  },
  {
    id: 25,
    category: "danie_główne",
    title: "Polędwica wołowa",
    content:
      "1 porcja wcześniej przygotowanych polędwiczek wrzucić do cyrkulatora (4-6 minut), gnocchi ziemniaczane (około 14 szt) gotowane, przesmażone na maśle, blanszowane 4 różyczki z brokuła podsmażone razem z gnocchi, przygotowanie sou z podgrzybka( śmietanę zagrzać z sosem z pod-grzybków) sposób podania: kwadratowy płaski talerz, na środek sos z podgrzybków przesmażone gnocchi i brokuły ładnie poukładane, kawałki polędwiczki przesmażone z jednej strony, całość posypana natką pietruszki.",
    image: "beeftenderloin.webp",
    video: "beeftenderloin.mp4",
    ingridients: {
      Polędwica_wieprzowa: "-",
      Gnocchi_ziemniaczane: "-",
      Brokuła: "-",
      Sos_z_podgrzybka: "-",
      Natka_pietruszki: "-",
    },
  },
  {
    id: 26,
    category: "danie_główne",
    title: "Rissotto",
    content: "",
    image: "risotto.webp",
    video: "risotto.mp4",
    ingridients: {
      Risotto: "-",
    },
  },
  {
    id: 27,
    category: "pizza",
    title: "Margherita ",
    content:
      "Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzamargherita.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "1,5 łyżki",
      ser_mozzarella_galbani: "110 g",
      suszone_oregano: "-",
    },
  },
  {
    id: 28,
    category: "pizza",
    title: "Salame",
    content:
      "Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzasalame.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "1,5 łyżki",
      ser_mozzarella_galbani: "110 g",
      suszone_oregano: "-",
      Salami: "8 plastrów",
    },
  },
  {
    id: 29,
    category: "pizza",
    title: "Capricciosa",
    content:
      "Szynka konserwowa pokrojona w plastry i każdy plaster na cztery(12 małych plasterków) pieczarki cienko krojone w plastry lub pół plastry, suszone oregano. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzacapriciosa.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "1,5 łyżki",
      ser_mozzarella_galbani: "110 g",
      szynka_konserwowa: "-",
      pieczarki: "-",
      karczochy: "-",
      suszone_oregano: "-",
    },
  },
  {
    id: 30,
    category: "pizza",
    title: "Diavolo",
    content:
      "Sos pomidorowy 1 łyżka do serwowania + 1 łyżka stołowa sosu ostrego, ser mozzarella galbani 0,11 kg, Salami Picante ostre 14 małych plastrów, papryka jalapeno krojona w plastrach, suszone chilli w płatkach, suszone oregano. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzadiavolo.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "-",
      ser_mozzarella_galbani: "110 g",
      sos_ostry: "-",
      Salami_Picante: "-",
      papryka_jalapeno: "-",
      suszone_chilli: "-",
      suszone_oregano: "-",
    },
  },
  {
    id: 31,
    category: "pizza",
    title: "Montagna",
    content:
      "Sos pomidorowy 1,5 łyżki do serwowania, ser mozzarella galbani 0,11 kg,rolada ustrzycka 8 plastrów, cebula biała pokrojona w krążki, plastry bekonu 3 szt pokrojone na trzy(chipsy z boczku), polane zmiksowaną żurawiną suszone oregano. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzamontagna.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "-",
      ser_mozzarella_galbani: "110 g",
      rolada_ustrzycka: "-",
      cebula_biała: "-",
      plastry_bekonu: "-",
      żurawin: "-",
      suszone_oregano: "-",
    },
  },
  {
    id: 32,
    category: "pizza",
    title: "Pesce ",
    content:
      "Sos pomidorowy 1,5 łyżki do serwowania, ser mozzarella galbani 0,11 kg, tuńczyk z puszki odsączony z zalewy 0,06 kg, cebula biała pokrojona w krążki, po upieczeniu świeży koperek, suszone oregano. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzapesce.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "-",
      ser_mozzarella_galbani: "110 g",
      tuńczyk_z_puszki: "60g",
      cebula_biała: "-",
      koperek: "-",
      suszone_oregano: "-",
    },
  },
  {
    id: 33,
    category: "pizza",
    title: "Gambretto",
    content:
      "Sos pomidorowy 1,5 łyżki do serwowania, ser mozzarella galbani 0,11 kg, 4 szt krewetek przecięte wzdłuż ułożone tak aby po pokrojeniu pizzy na każdym kawałku była 1 szt, cukinia pokrojona w cienkie plastry 12szt, po upieczeniu posypać pomidorkami co idą do bruschetta i posiekaną kolendrą, oregano suszone. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzagamberetto.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "-",
      ser_mozzarella_galbani: "110 g",
      krewetki: "4 szt.",
      cukinia: "-",
      pomidor: "-",
      kolendra: "-",
      suszone_oregano: "-",
    },
  },
  {
    id: 34,
    category: "pizza",
    title: "Formaggi",
    content:
      "Sos pomidorowy 1,5 łyżki do serwowania, ser mozzarella galbani 0,11 kg, rolada ustrzycka 8 plastrów, kawałki gorgonzoli 0,05 kg, po upieczeniu płatki migdałów, płatki parmezanu suszone oregano. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzaformaggi.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "-",
      ser_mozzarella_galbani: "110 g",
      rolada_ustrzycka: "",
      gorgonzola: "50 g",
      migdały: "-",
      parmezan: "-",
      suszone_oregano: "-",
    },
  },
  {
    id: 35,
    category: "pizza",
    title: "Bianca",
    content:
      "Sos czosnkowy, ser mozzarella galbani 0,11 kg, pieczarki cienko krojone w plastry lub półplastry, gruszka pokrojona w cienkie plastry na krajalnicy, ser kozi kruszony 0,04 kg,po upieczeniu rukola i płatki parmezanu, oregano. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzabianca.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "-",
      ser_mozzarella_galbani: "110 g",
      pieczarki: "",
      gruszka: "-",
      ser_kozi: "40 g",
      rukola: "-",
      parmezan: "-",
      oregano: "-",
    },
  },
  {
    id: 36,
    category: "pizza",
    title: "Prosciutto",
    content:
      "Sos pomidorowy 1,5łyzki do serwowania, ser mozzarella galbani 0,11kg, po upieczeniu 4plastry szynki parmeńskiej po pół na każdy kawałek pizzy, rukola skropiona oliwą pomace/sansa, pomidorki cherry krojone na 1/2 lub 1/4 , posypane płatkami parmezanu, suszone oregano. Pizza ma mieć wymiar 32 cm cienkie ciasto i puszysty chrupiący rant.",
    image: "pizzaprosciutto.webp",
    video: "pizza.mp4",
    ingridients: {
      sos_pomidorowy: "-",
      ser_mozzarella_galbani: "110 g",
      szynka_parmeńska: "",
      rukola: "-",
      oliwa_pomace_lub_sansa: "-",
      pomidorki_cherry: "-",
      parmezan: "-",
      suszone_oregano: "-",
    },
  },
];
